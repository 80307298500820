@import '~antd/dist/antd.css';

* {
    box-sizing: border-box;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    white-space:keep-all;
    hyphens: none;
    -ms-word-break: break-word;
    -ms-hyphens: none;
    -moz-hyphens: none;
    -webkit-hyphens: none;
  }
 

body,
#root,
.bws-header {
  min-width: 320px;
  font-family: 'Freude','yorkten-slab-normal', 'Arial';
 
}


.MuiBottomNavigationAction-wrapper{
 padding:0px;
 margin:0px;
}

.MuiBottomNavigationAction-root{
  margin:0px;
}


html {
    scroll-behavior: smooth;
    
}

.pageBackground {
  background-repeat: repeat;
  background-position: top;
  overflow: auto;

}

.sectionContent{
 width: 100%;
 margin-top: 0px;
 padding:16px 16px 48px 16px;
}

.pageContent{
 width: 100%;
 height: 100vh;
 margin-top: 0px;
 padding:16px 16px 48px 16px;
}


.web-content{
 width: 100%;
 margin-top: 40px;
}

.text-darkblue{
  color:rgba(45, 52, 95, 1);
}

.bg-white {
 background-color: white;
}

.bg-blue{
  background-color: rgba(175,214,231, 1);
}

.bg-lightblue{
  background-color: rgba(237,246,249, 1);
}


.bg-darkblue{
  background-color: rgba(45, 52, 95, 1);
}

.text-purple{
  color:rgba(121,63,121, 1);
}

.bg-purple{
  background-color: rgba(201,149,189, 1);
}

.text-green{
 color:rgba(95, 181, 173,1);

}

.bg-green{
 background-color:rgba(95, 181, 173,1);

}

.bg-bluegreen{
 background-color:rgba(147,205,200,1);
}

.text-orange1{
 color:rgba(237, 114, 97, 1);

}

.bg-orange1{
 background-color:rgba(245,175,166,1);
}

.text-darkorange{
 color:rgba(239, 128, 104, 1);

}

.bg-orange2{
 background-color:rgba(244,167,150,1);
}


.text-orange2{
 color:rgba(245,162,68, 1);
}

.text-orange3{
 color:rgba(245,175,166, 1);
}

.bg-orange3{
 background-color:rgba(245,175,166,1);
}


.text-coral{
  color:rgba(255, 133, 148, 1);
}
.bg-coral{
  background-color: rgba(255,184,193, 1);
}

.text-pink{
  color:rgba(255, 133, 148, 1);
}
.bg-pink{
  background-color: rgba(255,159,170, 1);
}
.bg-pink1{
  background-color: rgba(255,159,170, .75);
}

.bg-pink2{
  background-color: rgba(255,159,170, .55);
}

.bg-pink3{
  background-color: rgba(255,159,170, .4);
}

.text-orange{
 color:rgba(237, 114, 97, 1);
}

.text-yellow{
 color:rgba(251,188,39, 1);
}

.text-black{
 color:black;

}

.text-white{
 color:white;
}

.bg-lightpink{
  background-color: rgba(255,233,236, 1);
}

.bg-lightgreen{
  background-color: rgba(233, 243, 239, 1);
}
.bg-lightorange{
  background-color: rgba(254, 224, 213, 1);
}

.bg-lightpink-gradient{
  background:linear-gradient(white, transparent);
  background-color:rgba(201,149,189, 1) ;
}

.fl-left{
  float:left;

}
.fl-right{
   position: relative;
   float:right;
   z-index: 999;
}
.center{
  text-align:center;
}


.big-title{
  text-align: left;
  font-size: 3em;
  margin: 0px;
  opacity: .87 ;
}

.title{
  text-align: left;
  font-size: 2.5em;
  margin-bottom: 0px;
  margin-top: 0px;
  opacity: .87 ;
}
.subtitle{
  text-align: left;
  font-size: 1.75em;
  margin-bottom: 0px;
  margin-top: 8px;
  opacity: .87 ;
}



.support-box{
  text-align: left;
  margin: 16px 8px 16px 20px;
  padding: 8px;
  font-size:1em;
  font-weight: 500;
  background-color: white;
  border: dotted 2px lightgrey ;
  font-family: yorkten-slab-normal, sans-serif;

  a{
    margin:0px;
    padding:0px;
    font-weight: 900;
   
  }
  
p{
  text-align: left;
  font-size: 1em;
  font-weight: 500;
  margin: 0px;
  opacity: .87 ;
  padding:2px 40px 0px 8px;
}

}

.support-title{
  text-align: left;
  font-size: 1.25em;
  font-weight:800;
  margin: 0px;
  opacity: .87 ;
  padding:2px 60px 0px 8px;
  
}

.support-line{
  background-color:rgba(245,162,68, 1);
  border-color:transparent;
  box-shadow:none;
  outline-color:transparent;

}

.support-subtitle{
  text-align: left;
  font-size: 1em;
  font-weight: 600;
  margin: 0px;
  opacity: .87 ;
  padding:2px 40px 0px 8px;
}

.support-address{
  text-align: left;
  font-size: 1em;
  font-weight: 500;
  margin: 0px;
  opacity: .87 ;
  padding:2px 40px 0px 8px;
}

.support-contact{
  text-align: left;
  font-size: 1em;
  font-weight: 800;
  margin: 0px;
  opacity: .87 ;
  padding:2px 40px 0px 8px;
}



.big-button {
  border-radius:8px;
  border: 1px solid transparent;
  padding: 16px 4px 16px 16px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  margin: 0px 0px 8px 0px; 
   box-shadow: 1px 1px darkgrey;
}

.bigger-button {
  border-radius:8px;
  border: 1px solid transparent;
  padding: 16px 4px 16px 16px;
  box-shadow: 1px 1px darkgrey;
  cursor: pointer;
  display:inline-block;
  width: 100%;
  margin: 0px 0px 16px 0px; 
}


.small-button {
  border-radius:8px;
  border: 1px solid transparent;
  padding: 4px 16px 4px 8px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  margin: 0px 0px 8px 0px; 
   box-shadow: 1px 1px darkgrey;
}


.smaller-button {
  border-radius:8px;
  border: 1px solid transparent;
   padding: 4px 8px 4px 8px;
  box-shadow: 1px 1px darkgrey;
  cursor: pointer;
  display:inline-block;
  width: 100%;
  margin: 0px 0px 8px 0px; 
}

.button-body{
  float:left;
}

.button-body2{
  margin-right: 8px;
  padding-right:0px;
}

.button-heading{
  text-align: left;
  margin:0px;
  margin-top: 4px;
  opacity: .87;
  font-size: 1.5em;
}

.button-heading-small{
  text-align: left;
  margin:0px;
  margin-top: 4px;
  opacity: .87;
  font-size: 1em;
}

.hub-heading{
  text-align: left;
  margin:0px;
  margin-top: 4px;
  opacity: 1;
  font-size: 1.5em;
  z-index: 899;
  width:100%;
  padding:16px;
}

.hub-heading-browser{
  text-align: left;
  margin:4px 16px 0px 16px;
  opacity: 1;
  font-size: 1.6em;
  z-index: 899;
  padding:16px;
  width:auto;
}

.button-detail{
    text-align: left;
    margin: 0px;
    font-family: yorkten-slab-normal, sans-serif;
    font-size: 1.25em;
    }

    

.button-detail-small{
    text-align: left;
    margin: 0px;
    font-family: yorkten-slab-normal, sans-serif;
    font-size: 1em;
    }

.button-arrow{
  float: right;
  margin: 0px;
  padding:0px;
  opacity: .87;
  font-size: 2em;
}

.button-arrow-size{
  font-size: 1.5em;
}

.information-box{
  text-align: center;
  margin: 16px;
  padding: 16px;
  border: thin solid grey;
  box-shadow: 4px 4px grey;
  opacity: .87;
  font-size:1.5em;
}

.info-box{
  text-align: center;
  margin: 16px;
  padding: 16px;
  opacity: .87;
  font-size:1.5em;
}


.heading-box{
  text-align: left;
  margin: 0px 0px 0px 0px;
  padding: 8px 24px 8px 16px;
  font-size:1.4em;
  font-weight: 600;
  font-family: yorkten-slab-normal, sans-serif;
  
}



.search-item{
  text-align: left;
  margin: 0px 0px 0px 0px;
  padding: 8px 16px;
  font-size:1em;
  font-weight: 500;
  font-family: yorkten-slab-normal, sans-serif;
  border-top: thin dotted grey;
  
}


.search-item .title{
  font-weight: 600;
  font-size:1.1em;
}

.search-item .subtitle{
  font-weight: 600;
  font-size:1em;
  margin-left:16px; 
  display: list-item;                                                    
  list-style-type: disc;      
  list-style-position: inside;
}





.search-item .subtitle .subtitle-item{
  font-size:1em;
  margin-left:20px; 
  font-weight:300;
  margin-bottom:8px;
}

.search-item .link{
  font-weight: 600;
  font-size:1em;
  margin-top:4px;
  
}


.fav-item{
  text-align: left;
  margin: 0px;
  padding: 8px 16px;
  font-size:1em;
  font-weight: 500;
  font-family: yorkten-slab-normal, sans-serif;
  border-bottom: thin dotted grey;
}



.fav-item .title{
  font-weight: 600;
  font-size:1.1em;
}

.fav-item .subtitle{
  font-weight: 600;
  font-size:1em;
  margin-left:16px; 
  display: list-item;                                                    
  list-style-type: disc;      
  list-style-position: inside;
}

.fav-item .subtitle .subtitle-item{
  font-size:1em;
  margin-left:20px; 
  font-weight:300;
  margin-bottom:8px;
}

.fav-item .link{
  font-weight: 600;
  font-size:1em;
  margin-top:4px;
  
}



.address-box-background{
  z-index: 999;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align:center;
  width:200px;
  padding:40px 16px 16px 10px;
   p{
      padding:0px;
      line-height:14px;
      }
    a{ 
      font-size:1em;
      color:rgba(121,63,121, 1);
      padding-bottom:4px;
      z-index: 1000;
    } 
}
.lc-address-box-background{
  z-index: 999;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  text-align:center;
  margin-left:0px;
  margin-bottom:24px;
  padding:40px 16px 16px 24px;
   p{
      padding:0px 24px;
      line-height:14px;
      }
    a{ 
      font-size:1em;
      color:rgba(76,144,138,1);
      padding-bottom:4px;
      z-index: 1000;
    } 
}

.lcc-address-box-background{
  z-index: 999;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  text-align:center;
  margin-left:0px;
  margin-top:16px;
  margin-bottom:36px;
  padding:40px 16px 16px 8px;
   p{
      padding:0px 24px;
      line-height:14px;
      }
    a{ 
      font-size:1em;
      color:rgba(237,114,97,1);
      padding-bottom:4px;
      z-index: 1000;
    } 
}


.w-address-box-background{
  z-index: 999;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  text-align:center;
  margin-left:0px;
  padding:40px 4px 8px 4px;
   p{
      padding:0px 24px;
      line-height:14px;
      }
    a{ 
      font-size:1em;
      color:rgba(237,114,97,1);
      padding-bottom:4px;
      z-index: 1000;
    } 
}

.family-box-background{
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  margin:0px;
  width: 100%;
  padding:44px 24px 24px 40px;
  font-family:yorkten-slab-normal, sans-serif;
   p{
      padding:0px 24px;
      line-height:14px;
      color:white;
      font-size:1.5em;
      font-weight:900;
      }
   
}


.banner-box-background{
  float:left;
  z-index: 999;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align:center;
  width:auto;
}


.support-box-background{
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  text-align:center;
  width:auto;
  margin:0px -16px;
 
   p{
      padding:40px 30px 24px 30px;
      color:white;
      font-size:1em;
      font-weight:900;
      font-family:yorkten-slab-normal, sans-serif;
      }
}



.image-float-right {
  float: right;
  padding: 24px 0px 16px 16px;
  z-index:899;
}



.arrow-float-right {
  float: right;
  padding: 4px 4px 16px 16px;
  z-index:999;
  opacity:1;
  .upIcon{
    color:black;
    background-color:pink;
    border-radius:16px;
    padding:4px;
    font-size: 2em;
     opacity:1;
  }
}

.up-arrow-float-right {
  float: right;
  margin-top:-48px;
  // margin-right:16px;
  padding: 4px 20px 16px 16px;
  z-index:  999;
  opacity:1;
    .upIcon{
    color:white;
    background-color:black;
    border-radius:16px;
    padding:4px;
    font-size: 2em;
     opacity:1;
  }
}


.image-float-left {
  float: left;
  padding: 24px 0px 16px 0px;
  z-index:899;
}

.results-box{
  text-align: center;
  margin: 16px;
  padding: 16px;
  font-size:1.2em;
  font-weight: 600;
  font-family: yorkten-slab-normal, sans-serif;
  
}

.acknowledge-box{
  background-color: purple;
  // margin:24px 8px 24px 20px;
  padding:16px;
  color:white;
  font-style: italic;
  font-weight:500;
  font-family: yorkten-slab-normal, sans-serif;
  font-size:1em;
  text-align:center;
}

.link-box{
  background-color:white;
  border: 1px double rgba(95, 181, 173,1);
  margin:0px 8px 24px 8px;
  padding:24px 8px 12px 12px;

  p{
    font-size: 1.25em;
    cursor: pointer;
  }

}


.link-box .bullet-item{
 font-size: 1.25em;

}

.note-box{
  text-align: center;
  margin: 8px;
  padding: 8px;
  font-size:1em;
  font-weight: 600;
  font-family: yorkten-slab-normal, sans-serif;
  width:50%;
  a{color: black;}
}

.search-box{
  text-align: left;
  margin: 0px;
  padding: 16px 0px 16px 16px;
  font-size:1em;
  font-weight: 500;
  font-family: yorkten-slab-normal, sans-serif;
  border-bottom:  thin solid lightgrey;
  display:block;
  background-color:white;
}

.search-box .title{
  font-weight: 600;
  font-size:1.1em;
  margin:0px 60px 0px 0px;
  padding:0px 0px 0px 0px;
  
}

.search-box .bookmark{
  font-weight: 600;
  font-size:1.1em;
  margin:-24px 16px 0px 0px;
  padding:0px 0px 0px 0px;
  float:right;
}



.text-box{
  text-align: left;
  margin: 8px 8px 16px 16px;
  padding: 8px 16px;
  font-size:1em;
  font-weight: 500;
  font-family: yorkten-slab-normal, sans-serif;
}

.bullet-item{
  font-weight: 600;
  font-size:1em;
  margin-left:16px; 
  display: list-item;                                                    
  list-style-type: disc;      
  list-style-position: outside;
  padding-bottom:4px;
  padding-right:24px;
  
}


.bullet-item-terms{
  font-weight: 600;
  font-size:1em;
  margin-left:16px; 
  display: list-item;                                                    
  list-style-type: disc;      
  list-style-position: outside;
  padding-bottom:4px;
  padding-right:16px;
  font-size:16px;
  font-family:'roboto';
  
}

.bullet-item-text{
  font-weight: 400;
  font-size:1em;
  margin-left:16px; 
  padding-right:24px;
}


.bullet-item-text-bold{
  font-weight: 900;
  font-size:1em;
  margin-left:16px; 
  padding-right:24px;
}

.text-box .title{
  font-weight: 600;
  font-size:1.1em;
}

.text-box .subtitle{
  font-weight: 600;
  font-size:1em;
  margin-left:16px; 
  display: list-item;                                                    
  list-style-type: disc;      
  list-style-position: inside;
}

.text-box .subtitle .subtitle-item{
  font-size:1em;
  margin-left:20px; 
  font-weight:300;
  margin-bottom:8px;
}



.text-box .link{
  font-weight: 600;
  font-size:1em;
  margin-top:4px;
  
}

.paragraph{
  text-align:'left';
  opacity: .87;
  font-family: 'roboto';
  margin-top:0px;
  font-size:1.2em;
}


 .link{
  font-weight: 600;
  font-size:1em;
  margin-top:4px;
}


 .email-link{
  font-weight: 500;
  font-size:1.1em;
  font-family: roboto;
  height:32px; 
  flex:1;
}
 .phone-link{
  font-weight: 900;
  font-size:1.2em;
  font-family: roboto;
  height:32px;
}

ul.list{
  line-height:24px;
  margin-top: 8px;
  li
  {
  opacity:.87;
  font-family: yorkten-slab-normal, sans-serif;
  font-size: 1.1em;
 }
}


ul.list-about{
  line-height:24px;
  margin-top: 8px;
  li
  {
    padding-top:8px;
    opacity:.87;
    font-family: roboto;
    font-size: 1.2em;
  }
}


.more{
  position: relative;
  float:right;
  z-index: 999;
  font: size 1.1em;
  padding:0px 0px 0px 16px;
  margin:0px;
  
}


.boxAppear {
  animation-name: appear;
  animation-duration: 1s;
}

div.vert-move {
    -webkit-animation: mover .5s infinite  alternate;
    animation: mover .5s infinite  alternate;
}


img.vert-move {
    -webkit-animation: mover .5s infinite  alternate;
    animation: mover .5s infinite  alternate;
}


img.side-move {
    -webkit-animation: mover .5s infinite  alternate;
    animation: sidemover .5s infinite  alternate;
}

img.side-move-fast {
    -webkit-animation: sidemoverfast .1s infinite  alternate;
    animation: sidemoverfast .1s infinite  alternate;
}


@keyframes appear {
  0%   {opacity: 0;}
  25%  {opacity: .25;}
  50%  {opacity: .5;}
  100% {opacity:1;}
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-3px); }
}

@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-3px); }
}

@-webkit-keyframes sidemover {
    0% { transform: translateX(0); }
    100% { transform: translateX(-2px); }
}

@keyframes sidemover {
    0% { transform: translateX(0); }
    100% { transform: translateX(-2px); }
}


@-webkit-keyframes sidemoverfast {
    0% { transform: translateX(0); }
    100% { transform: translateX(-1px); }
}

@keyframes sidemoverfast {
    0% { transform: translateX(0); }
    100% { transform: translateX(-1px); }
}


.ant-menu-item.ant-menu-item-selected{
  color:#004a75;  
  }

  .ant-menu-item.ant-menu-item:hover {
    color:#006caa;

  }

  .ant-empty-description{
    color:black;
    opacity: .75;
  }


.ant-dropdown-menu-item {
  padding: 6px 12px !important;
}

.ant-menu-dark {
  .ant-menu-item:hover {
    background-color: #013a70;
  }

  .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-selected {
    color: rgba(255, 255, 255, 0.65);
    .ant-menu-submenu-title:hover {
      background-color: #004a75;
      color: rgba(255, 255, 255, 0.65) !important;
    }
    
  }
}

.ant-col{
  z-Index:999;  
}


.align-middle {
  vertical-align: middle;
}

.overflow-auto {
  overflow: auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-red {
  color: #f5222d;
}

.text-blue {
  color: #006caa;
}


.important-message{
  background-color:#013a70;
  color: white;
  font-size:16px;
  text-align:center;
  padding:8px;
  font-weight: 300;
  
}
   
.mobileLayout{
    // height:100vh;
    display:flex;
    flex-direction: column;
}


.content{
    margin:0px;
    padding:0px; 
    flex:1;
    overflow:auto;
}


.mobileHeaderStyle{
    position:fixed;
    width:100%;
    padding:0;
    margin:0;
    top:0px;
    left:0px;
    height:56px; 
    display:block;       
    }


.mobileContent{
    margin:0px;
    margin-top:56px;
    padding-top:0px;
    scroll-padding-top: 56px;
    padding:0px; 
    flex:1;
    overflow:auto;
}

  .line{
      height:1px;
      background-color:lightgray;
      border-width:0px;
      margin:0px;
      padding:0px;
      box-shadow: none;
    } 

    
 .mobileMenu{
    position:fixed;
    width:100%;
    left:0px;
    height:64px;
    bottom:0;
 }

 .search-menu-style
 {  position:fixed;
    width:100%;
    padding:0px;
    margin:0;
    top:56px;
    left:0px;
    height:72px; 
    background-color: white;
    opacity:1;
    display:block;    
    z-index: 1001;  
    border-top: thin solid darkgrey; 
    border-bottom: thin solid darkgrey; 

    }
    
.search-content{
    margin:0px;
    margin-top:72px;
    padding:0px; 
    flex:1;
    overflow:auto;
    background-color:white;
    height:100vh -64px;
}

.search-content-empty{
    margin:0px;
    margin-top:64px;
    padding:0px; 
    flex:1;
    overflow:auto;
    background-color:white;
    // height:100vh - 4px;
    height:100vh
}

.fav-content{
    margin:0px;
    margin-top:0px;
    padding:0px; 
    flex:1;
    overflow:auto;
    height:100vh ;
}

.fav-content-empty{
    margin:0px;
    margin-top:0px;
    padding:0px; 
    flex:1;
    overflow:auto;
    height:100vh ;
}

  
.search-heading{
   display: none;
}
    


@media (min-width:768px)
{
 
.navBarItem {
    // width: auto;
    text-align: center;
    }


.arrow-float-right {
  float: right;
  padding: 4px 4px 16px 16px;
  z-index:999;
  opacity:1;
  .upIcon{
    color:black;
    background-color:pink;
    border-radius:20px;
    padding:4px;
    font-size: 2.5em;
     opacity:1;
  }
}

.up-arrow-float-right {
  float: right;
  margin-top:-48px;
  // margin-right:16px;
  padding: 4px 20px 16px 16px;
  z-index:  999;
  opacity:1;
    .upIcon{
    color:white;
    background-color:black;
    border-radius:20px;
    padding:4px;
    font-size: 2.5em;
     opacity:1;
  }
}
.toolbar{
 height: 136px;
 width:100%;
 position:fixed;
 top:0;
 left:0;
 background-color:white;
 display:block;
 justify-content: left;
 align-items:center;
 padding: 0;
 box-sizing:border-box;
 z-index:9999;
 
} 
 
.search-heading{
  margin: 0px 0px 16px 0px;
  padding: 8px 16px 8px 16px;
  font-size:1.1em;
  font-weight: 600;
  font-family: yorkten-slab-normal, sans-serif;
  display:block;

} 
     
  .contentPage{
        overflow: hidden;
        scroll-padding-top: 100px;
        padding:16px;
        margin-top:0px;
  }
  
   

.content{
    position:relative;
    top: 136px;
    margin:0px;
    flex:1;
    overflow:auto;
    scroll-padding-top: 160px;
    }


.web-content{
 width: 100%;
 margin-top: 16px;
 padding:16px;
}

}


@media (min-width:1024px)
{
   

.arrow-float-right {
  float: right;
  padding: 4px 4px 16px 16px;
  z-index:999;
  opacity:1;
  .upIcon{
    color:black;
    background-color:pink;
    border-radius:32px;
    padding:4px;
    font-size: 4em;
     opacity:1;
  }
}

.up-arrow-float-right {
  float: right;
  margin-top:-48px;
  // margin-right:16px;
  padding: 4px 20px 16px 16px;
  z-index:  999;
  opacity:1;
    .upIcon{
    color:white;
    background-color:black;
    border-radius:32px;
    padding:4px;
    font-size: 4em;
     opacity:1;
  }
}



.toolbar{
 height: 160px;
 width:100%;
 position:fixed;
 top:0;
 left:0;
 background-color:white;
 display:block;
 justify-content: left;
 align-items:center;
 padding: 0;
 margin:0;
 box-sizing:border-box;
 z-index:1000;
 border-bottom: thin solid lightgrey;
 
} 
   
  .contentPage{
        overflow: hidden;
        scroll-padding-top: 148px;
        padding:16px;
        margin-top:0px;
  }
  
    
// .headerStyle{
//     height:200px;  
//     width:100%;
//     position:fixed;
//     top:0;
//     left:0;
//     padding:0;
//     margin:0;
//     display:block;    
//     }

.content{
    top:160px;
    margin-top:0px;
    overflow: hidden;
    scroll-padding-top: 160px;
    background-color:white;
    }


  
.navBar{
     position:fixed;
     top:112px;
     height:48px;
      justify-content: center;
      align-items:center;
       font-size:'18px';
      margin:'0px';
      padding:'0px';
     
     
}

.navBarItem {
   
    text-align: center;
   
   
    }
    
  
.heading-box{
  text-align: left;
  margin: 0px 0px 16px 0px;
  padding: 8px 24px 8px 16px;
  font-size:1.8em;
  font-weight: 600;
  font-family: yorkten-slab-normal, sans-serif;
  
}

.hub-heading{
  text-align: left;
  margin:0px;
  margin-top: 4px;
  opacity: 1;
  font-size: 1.5em;
  z-index: 899;
  width:100%;
  padding:16px;
}

.hub-heading-browser{
  text-align: left;
  margin:4px 16px 0px 16px;
  opacity: 1;
  font-size: 2em;
  z-index: 899;
  padding:16px;
  width:auto;
}


}
